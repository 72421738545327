/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class AdminPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match('viewAdmins');
    this.create = permissionChecker.match('createAdmin');
    this.edit = permissionChecker.match('editAdmin');
    this.destroy = permissionChecker.match('deleteAdmin');

    this.changeStatus = permissionChecker.match('changeAdminStatus');
    this.print = permissionChecker.match('printAdminsTable');
    this.export = permissionChecker.match('exportAdminsTable');
    this.accessPages = permissionChecker.match('setAccessPermissions');
  }
}
